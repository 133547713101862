import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../../components/v2/Layout';
import { getUrlParams } from '../../../helpers/location';
import { createEmoriRoomByIdSelector } from '../../../reducers/emori';
import EmoriFormRoom from '../../../components/EmoriFormRoom';

const useStyles = makeStyles(theme => ({
  root: {},
}));

export default ({ location }) => {
  const classes = useStyles();
  const params = getUrlParams(location.search);
  const { id } = params;

  return (
    <Layout bg='bg4' title='แก้ไขห้อง'>
      <EmoriFormRoom readOnly={true} id={id}/>
    </Layout>
  );
};
